<template>
    <div class="py-15">
        <v-container>
            <v-row class="justify-center">
                <v-col cols="12" md="10" lg="8">
                    <v-expansion-panels focusable>
                        <v-expansion-panel v-for="(item,i) in categories" :key="i">
                            <v-expansion-panel-header>{{item.title}}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr v-for="(row, i) in item.content" :key="i">
                                                <td>{{ row.name }}</td>
                                                <td>{{ row.price }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            <v-row class="justify-center">
                <v-btn color="primary" large>
                    Оставить заявку 
                </v-btn>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'InstallPrice',
        data () {
            return {
                categories: [
                    {
                        title: 'Оформление гирляндами',
                        content: [
                            {
                                name: 'Монтаж гирлянды «Бахрома»',
                                price: '250руб./м.п.',
                            },
                            {
                                name: 'Монтаж гирлянды «Белт-Лайт»',
                                price: '250руб./м.п.',
                            },
                            {
                                name: 'Монтаж ламп под «Белт-Лайт»',
                                price: '15руб./шт.',
                            },
                            {
                                name: 'Монтаж гирлянды «Нить»',
                                price: '100руб./м.п.',
                            },
                            {
                                name: 'Монтаж гирлянды «Нить» фигурно',
                                price: '150руб./м.п.',
                            },
                            {
                                name: 'Монтаж гирлянды «Занавес-Дождь-Сеть» прямо',
                                price: '350руб./м2',
                            },
                            {
                                name: 'Монтаж гирлянды «Занавес-Дождь-Сеть» фигурно',
                                price: '500руб./м2',
                            },
                            {
                                name: 'Монтаж гирлянды «Еловая лапа»',
                                price: '250руб./шт',
                            },
                            {
                                name: 'Монтаж гирлянды «Еловая лапа» + гирлянда нить+декор элементы',
                                price: '800руб./шт',
                            },
                        ]
                    },

                    {
                        title: 'Контурная подсветка', 
                        content: [
                            {name: 'Монтаж гирлянды «Дюралайт»', price: '200руб./м.п.'},
                            {name: 'Монтаж гирлянды «Дюралайт»  с установкой профиля', price: '300руб./м.п.'},
                            {name: 'Монтаж гирлянды «Неон»', price: '350руб./м.п.'},
                            {name: 'Монтаж гирлянды «Неон» с установкой профиля', price: '450руб./м.п.'},
                            {name: 'Монтаж SMD ленты', price: '200руб./м.п.'},
                            {name: 'Монтаж SMD ленты с установкой профиля', price: '350руб./м.п.'},
                        ]
                    },
                    {
                        title: 'Световые мотивы', 
                        content: [
                            {name: 'Монтаж светового мотива до 1,5 м', price: '300руб./шт'},
                            {name: 'Монтаж светового мотива более 1,5 м', price: '1000руб./шт'},
                            {name: 'Монтаж светового мотива более 2,5 м', price: '2500руб./шт'},
                            {name: 'Монтаж консоли на опору освещения', price: '3000руб./шт'},
                            {name: 'Установка светодиодного дерева высотой до 1,5 м', price: '500руб./шт'},
                            {name: 'Установка светодиодного дерева высотой более 1,5 м', price: '1000руб./шт'},
                            {name: 'Установка акриловых фигур высотой до 1,5 м', price: '500руб./шт'},
                            {name: 'Установка акриловых фигур высотой более 1,5 м', price: '1000руб./шт'},
                        ]
                    },
                    {
                        title: 'Проводка и подключение', 
                        content: [
                            {name: 'Подключение гирлянд к блоку питания', price: '500руб./шт.'},
                            {name: 'Прокладка открытой электропроводки', price: '80руб./м.п.'},
                            {name: 'Прокладка электропроводки в гофре', price: '150руб./м.п.'},
                            {name: 'Прокладка электропроводки в кабель-канале', price: '200руб./м.п.'},
                            {name: 'Установка наружного выключателя/розетки', price: '350руб./шт.'},
                            {name: 'Монтаж и настройка датчиков движения, таймеров, фотореле, диммера', price: '500руб./шт.'},
                            {name: 'Установка и подключение автомата однополюсного', price: '500руб./шт.'},
                            {name: 'Установка и подключение УЗО', price: '800руб./шт.'},
                            {name: 'Установка и подключение электрощитка', price: '1000руб./шт.'}
                        ]
                    },
                    {
                        title: 'Крепежная система', 
                        content: [
                            {name: 'Монтаж профиля', price: '150руб./м.п.'},
                            {name: 'Монтаж троса', price: '80руб./м.п.'},
                        ]
                    },
                    {
                        title: 'Повышающие коэффициенты', 
                        content: [
                            {name: 'Высота объекта от 8 до 10м', price: '1,2/коэф.'},
                            {name: 'Высота объекта выше 10 м', price: '1,3/коэф.'},
                            {name: 'Оплата по безналичному расчету', price: '1,1/коэф.'}
                        ]
                    },
                    {
                        title: 'Прочее', 
                        content: [
                            {name: 'Выезд специалиста (бесплатно при заказе монтажа) + транспортные расходы', price: '1000руб.'},
                            {name: 'Минимальная стоимость монтажа', price: '10000руб.'},
                            {name: 'Расчет дополнительных транспортных расходов', price: ' '},
                            {name: 'до 20км по ЛО', price: '25руб./км.'},
                            {name: 'до 50км по ЛО', price: '20руб./км.'},
                            {name: 'от 50км по ЛО', price: '15руб./км.'},
                        ]
                    },
                ]
            }
        }

    }
</script>

<style>

</style>