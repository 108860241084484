<template>
  <div class="mini-spacer">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" sm="10" md="12" lg="5">
                        <div>
                            <form>
                                <v-row class="mt-15">
                                    <v-col cols="12" md="6" class="py-0">
                                        <v-text-field label="Ваше имя" outlined v-model="name" placeholder="Имя">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="py-0">
                                        <v-text-field label="Как с Вами связаться" outlined type="email" v-model="email"
                                            placeholder="Телефон или email"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="py-0">
                                        <v-textarea name="message" outlined label="Сообщение" rows="3"></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-btn class="btn-custom-md btn-primary-gradient mt-12" outlined
                                    color="white" elevation="0">
                                    Отправить
                                </v-btn>
                            </form>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="10" md="12" lg="4">
                        <div class="primary contact-detail-card">
                            <h5 class="detail-title font-weight-medium white--text">
                                Led Ded - производство, продажа и монтаж новогодних украшений
                            </h5>
                            <p class="mt-15 op-8 mb-0 white--text">+7-900-000-00-00</p>
                            <p class="op-8 mb-8 white--text">info@example.com</p>
                            <p class="mt-15 op-8 mb-0 white--text">Санкт-Петербург</p>
                            <p class="op-8 mb-0 white--text">Коломяжский пр. 17</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
</template>

<script>
export default {
    name: 'ContactForm',

}
</script>

<style>

</style>