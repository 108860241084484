<template>
  <v-container>
    <v-row>
      <v-col>
        <v-toolbar
            height="80"
            class="py-2"
        >
          <v-text-field
              v-model="input"
              class="my-auto"
              label="Что ищем?"
              outlined
              dense
              hide-details
          ></v-text-field>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <FilterProduct
              :sort="selectedSort"
              :maxprice="maxPrice"
              :minprice="minPrice"
              :selCategories="selectedCategories"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
      >
        <h1 
        class="white--text"
        v-if="selectedCategories.length === 1">
          {{selectedCategories[0]}}
        </h1>
        <h1 
        class="white--text"
        v-if="selectedCategories.length === 0">
          Товары из всех категорий
        </h1>

        <v-card
        class="px-5 py-2 mb-3"
        v-if="selectedCategories.length > 1"
        >
        <h3>
          Выбраны категории:
        </h3>
        <span>
          {{selectedCategories.join(', ')}}
        </span>
        </v-card>

        <v-card
            v-show="items.length === 0"
        >
          <v-card-title>
            Ничего не найдено
          </v-card-title>
        </v-card>
        <div class="text-center mb-3">
          <v-pagination
              v-show="allProducts.length > limit"
              v-model="page"
              :length="pages"
              :total-visible="10"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>

    <v-row
        justify="start"
        class="text-center">
      <v-col
          xl="2"
          lg="3"
          md="4"
          v-for="item in items"
          :key="item._id"
          :img-height="'200'"
          :max-width="'310'"
      >
        <Card
            class="mx-auto"
            :item="item"
            :img-height="'200'"
            :max-width="'310'"
            @click.native="itemShowing = item._id, $store.commit('addToViewed', item)"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
      >
        <div class="text-center mb-8">
          <v-pagination
              v-show="allProducts.length > limit"
              v-model="page"
              :length="pages"
              :total-visible="10"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>


    <v-row justify="center">
      <v-dialog
          persistent
          v-model="isModal"
          max-width="90%"
      >
        <CardDetail
            :item="itemShowing"
            @closeModal="itemShowing = ''"
        />
      </v-dialog>
    </v-row>
  </v-container>


</template>

<script>
import Card from "../components/Card";
import FilterProduct from "../components/FilterProduct";
import CardDetail from "../components/CardDetail";

export default {
  name: "Search",

  components: {
    Card,
    FilterProduct,
    CardDetail
  },

  props: ['query'],

  data () {
    return {
      input: this.query,
      limit: 12,
      page: 1,
      total: 0,
      isModal: false,
      itemShowing: ''
    }
  },

  computed: {
    allProducts: function () {
      let allProducts =[]
      let searchString = this.input !== undefined ? this.input.toLowerCase().trim() : ' '
      this.$store.state.allProducts.forEach((item) => {
        if (item.title.toLowerCase().includes(searchString)) {
          if (this.selectedCategories.length === 0 || this.selectedCategories.length > 0 && this.selectedCategories.some(f => f === item.breadcrumbs[2].title)) {
            if (+this.minPrice <= +item.price.split(' ').join('') || this.minPrice === '') {
              if (+this.maxPrice >= item.price.split(' ').join('') || this.minPrice === '') {
                let temp = {}
                for (let prop in item) {
                  temp[prop] = item[prop]
                }
                allProducts.push(temp)
              }
            }
          }
        }
      })
      this.sortAll(this.selectedSort, allProducts)
      return allProducts
    },
    
    items: function () {
      let forDisplay = this.allProducts.slice((this.page -  1) * this.limit, this.page * this.limit)
      return forDisplay
    },
    pages: function() {
      let remainder = this.allProducts.length % this.limit
      let additionalPage = remainder > 0 ? 1 : 0
      let lengthP = (this.allProducts.length - remainder) / this.limit
      return lengthP + additionalPage
    },

    selectedSort: function () {
      if (this.$route.query.sort) {
        return this.$route.query.sort
      }
      return 'default'
    },

    maxPrice: function () {
      if (this.$route.query.maxprice) {
        return this.$route.query.maxprice
      }
      return '300000'
    },

    minPrice: function () {
      if (this.$route.query.minprice) {
        return this.$route.query.minprice
      }
      return '0'
    },

    selectedCategories: function () {
      if (this.$route.query.categories) {
        return this.$route.query.categories.split(',')
      }
      return []
    },
  },

  watch: {
    input: function () {
      this.$router.push({ query: Object.assign({}, this.$route.query, { query: this.input }) });
    },

    itemShowing: function () {
      if (this.itemShowing !== '') {
        this.isModal = true;
        if (this.$route.query.item !== this.itemShowing) {
          this.$router.push({ query: Object.assign({}, this.$route.query, { item: this.itemShowing }) });
        }
      } else {
        this.isModal = false
        this.$router.push({ query: Object.assign({}, this.$route.query, { item: ''}) });
      }
    }
  },

  mounted() {
    if (this.$route.query.item) {
      this.itemShowing = this.$route.query.item
    }
    if (this.input === '') {
      this.input = ' '
    }
  },

  methods: {
    sortAll (param, arr) {
      this.page = 1
      switch (param) {
        case 'Цена: по возрастанию':
          arr.sort(function(a, b) {
            return +a.price.split(' ').join('') - +b.price.split(' ').join('');
          })
        break;

        case 'Цена: по убыванию':
          arr.sort(function(a, b) {
            return +b.price.split(' ').join('') - +a.price.split(' ').join('');
          })
        break;

        case 'Имя: по убыванию':
          arr.sort(function(a, b) {
            if (a.title < b.title) {return 1;}
            if (a.title > b.title) {return -1;}
            return 0;
          })
        break;

        case 'Имя: по возрастанию':
          arr.sort(function(a, b) {
            if (a.title < b.title) {return -1;}
            if (a.title > b.title) {return 1;}
            return 0;
          })
        break;

        default: arr.sort(function(a, b) {
            if (a._id < b._id) {return -1;}
            if (a._id > b._id) {return 1;}
            return 0;
          })
      }
    },
  }
}
</script>

<style scoped>

</style>
