<template>
    <div>
        <HeaderBanner
            :title="sections.header.title"
            :text="sections.header.text"
            :color="sections.header.color"
            :img="sections.header.img"
            />
        <TitleWithText 
            :title="sections.features.title" 
            :text="sections.features.text"
            :chip="sections.features.chip"/>
            
        <FeaturesSection :list="sections.features.list"/>

        <TitleWithText 
            :title="sections.portfolio.title" 
            :text="sections.portfolio.text"
            :chip="sections.portfolio.chip"/>

        <Portfolio :list="sections.portfolio.list"/>
        
        <TitleWithText 
            :title="sections.pricing.title" 
            :text="sections.pricing.text"
            :chip="sections.pricing.chip"/>

        <v-container>
            <v-row>
                <v-tabs v-model="tab">
                    <v-tab>
                        Пакетные тарифы
                    </v-tab>
                    <v-tab>
                        Единичные расценки
                    </v-tab>
                </v-tabs>
            </v-row>
        </v-container>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <Pricing :list="sections.pricing.list"/>
                    </v-tab-item>
                    <v-tab-item>
                        <InstallPrice/>
                    </v-tab-item>
                </v-tabs-items>

        <TitleWithText 
            :title="sections.contacts.title" 
            :text="sections.contacts.text"
            :chip="sections.contacts.chip"/>

        <ContactForm/>
    </div>
</template>

<script>
import HeaderBanner from "../components/pageComponents/HeaderBanner.vue";
import TitleWithText from "../components/pageComponents/TtitleWithText.vue";
import FeaturesSection from "../components/pageComponents/FeaturesSection.vue";
import Portfolio from "../components/pageComponents/Portfolio.vue";
import Pricing from "../components/pageComponents/Pricing.vue";
import InstallPrice from "../components/pageComponents/InstallPrice.vue"
import ContactForm from "../components/pageComponents/ContactForm.vue"


    export default {
        name: 'Installation',

        components: {
            HeaderBanner,
            TitleWithText,
            FeaturesSection,
            Portfolio,
            Pricing,
            InstallPrice,
            ContactForm
        },

        data () {
            return {
                tab: null,
                sections: {
                    header: {
                        title: 'Монтаж новогодних украшений',
                        text: 'Даже самое высокое качество изделия может быть перечёркнуту неправильным монтажем. На рынке монтажных услуг царит настоящий беспредел и найти среди большого количество дилетантов стоящих специалистов - задача непростая. Мы эту задачу решили. Наше решение доступно и для Вас. Заказывайте у нас монтажные услуги, и вы сбережёте свои нервы',
                        color: '#607df9',
                        img: require('@/assets/led-outdoor.webp')
                    },
                    features: {
                        chip: 'работайте с лучшими',
                        title: 'Преимущества',
                        text: 'Что делает наших монтажников стоящими специалистами? Ознакомьтесь со списком наших ключевых преимуществ',
                        list: [
                            {
                                id: 1,
                                title: 'Опыт',
                                text: 'Мы не пытаемся экономить на кадрах. Ведь квалифицированный труд должен достойно оплачиваться. Такой подход позволяет нам привлекать самых опытных специалистов. А специалисты, получая достойное вознаграждение, дорожат своей работой, что позволяет избегать текучки кадров',
                                icon: 'mdi mdi-star'
                            },
                            {
                                id: 2,
                                title: 'Оснащённость',
                                text: 'Используем проверенное монтажное оборудование. Приезжая на объект производства работ мы уверены, что не придётся прерывать произврдственный процесс из-за отказа оборудования.',
                                icon: 'mdi mdi-hammer-wrench'
                            },
                            {
                                id: 3,
                                title: 'Безопасность',
                                text: 'Учитывая, что в больнистве случае монтажные работы проводятся на высоте, техника безопасности выходит на первый план. Наши сотрудники регулярно проходят обучение безопасным методам производства работ и имеют подтверждаюшие документы',
                                icon: 'mdi mdi-certificate'
                            }
                        ]
                    },
                    portfolio: {
                        chip: 'наши работы',
                        title: 'Портфолио',
                        text: 'Наши работы - наша гордость',
                        list: [
                            {
                                id: 1,
                                title: 'Таврический сад',
                                text: 'Прекрасный Таврический сад, стал немного ярче к Новому году нашими усилиями', 
                                img: require('@/assets/images/portfolio/tavrick.jpg')
                            },
                            {
                                id: 2,
                                title: 'Отель Индиго',
                                text: 'Петербург город туристический и было бы странно, если бы среди наших клиентов не оказалось отеля', 
                                img: require('@/assets/images/portfolio/indigo.jpg')
                            },
                            {
                                id: 3,
                                title: 'Коттеджный посёлок Корабельные сосны',
                                text: 'Добавили волшебного сияния во дворе загородного дома', 
                                img: require('@/assets/images/portfolio/sosny.jpg')
                            },
                        ]
                    },
                    pricing: {
                        chip: 'цены',
                        title: 'Цены на монтажные работы',
                        text: 'Изо всех сил старались и нам удалось сохранить цены на уровне прошлого года',
                        list: [
                            {
                                title: 'start',
                                id: 1,
                                price: '15 000',
                                table: [
                                    {key: 'Длина', value: 'до 50 мп'},
                                    {key: 'Высота', value: 'до 4 м'},
                                    {key: 'Трудозатраты', value: 'до 8 ч'},
                                    {key: 'Варианты дизайна', value: '1'},
                                ]
                            },
                            {
                                title: 'standart',
                                id: 2,
                                price: '20 000',
                                table: [
                                    {key: 'Длина', value: 'до 100 мп'},
                                    {key: 'Высота', value: 'до 6 м'},
                                    {key: 'Трудозатраты', value: 'до 8 ч'},
                                    {key: 'Варианты дизайна', value: '2'},
                                ]
                            },
                            {
                                title: 'standart +',
                                id: 3,
                                price: '40 000',
                                table: [
                                    {key: 'Длина', value: 'до 200 мп'},
                                    {key: 'Высота', value: 'до 8 м'},
                                    {key: 'Трудозатраты', value: 'до 16 ч'},
                                    {key: 'Варианты дизайна', value: '3'},
                                ]
                            },
                            {
                                title: 'maxi',
                                id: 4,
                                price: '60 000',
                                table: [
                                    {key: 'Длина', value: 'до 300 мп'},
                                    {key: 'Высота', value: 'до 10 м'},
                                    {key: 'Трудозатраты', value: 'до 24 ч'},
                                    {key: 'Варианты дизайна', value: '3'},
                                ]
                            },
                        ]
                    },
                    contacts: {
                        chip: 'пишите',
                        title: 'Связаться с нами',
                        text: '',
                    }
                }
            }
        }
    }
</script>

<style>

</style>