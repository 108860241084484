<template>
  <v-card>
    <v-toolbar 
    class="bgr"
    dense>
      <v-toolbar-title
      class="white--text"
      >{{ product.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
      class="white--text"
          @click="$emit('closeModal'), indexActivePhoto = 0"
          icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container>
      <v-row
      class="justify-center"
      >
      <v-col class="flex-column col-12 col-md-4 col-lg-3 justify-center align-center d-flex">
        <div
        style="width: 80%;" 
        class="activePhoto">
        <img 
        :src="activePhoto" 
        width="100%"
        alt="">
      </div>
        <div 
        style="width: 80%;"
        class="img-gallery">
          <div
          :style="{boxShadow: photo === activePhoto ? '0 0 5px 2px #87c2fe' : '', transform: photo === activePhoto ? 'scale(0.9)' : ''}"
          v-for="(photo, i) in product.photos"
          :key="photo"
          @click="indexActivePhoto = i"
          :ref="photo"
          class="img-wrap">
            <img
            width="50"
            :src="photo" 
            alt="">
          </div>
        </div>
      </v-col>
      <v-col class="col-12 col-md-6 col-lg-9">
        <v-row>
          <v-container>
            <v-card-title>{{ product.title }}</v-card-title>
              <v-row class="justify-end">
                <v-col>
                  <v-card-title class="text-h4">
                    {{product.price}} рублей
                  </v-card-title>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-container>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            @click.stop="$store.commit('addToWishlist', product)"
                            class="mx-2 mb-3 pa-5"
                            v-bind="attrs"
                            v-on="on"
                            dark
                            small
                            :color="$store.state.wishlist.findIndex(p => p._id === product._id) === -1 ? 'rgba(72,162,253,0.65)' : '#a90000'"
                        >

                        {{$store.state.wishlist.findIndex(p => p._id === product._id) === -1 ? 'Добавить в избранное  ' : 'Убрать из избранного  '}}
                          <v-icon dark>
                            {{$store.state.wishlist.findIndex(p => p._id === product._id) === -1 ? 'mdi-heart-broken' : 'mdi-heart'}}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{$store.state.wishlist.findIndex(p => p._id === product._id) === -1 ? 'Добавить в избранное' : 'Убрать из избранного'}}</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            @click.stop="$store.commit('addToCart', product)"
                            class="mx-2 mb-3 pa-5"
                            v-bind="attrs"
                            v-on="on"
                            dark
                            small
                            :color="$store.state.cart.findIndex(p => p._id === product._id) === -1 ? 'primary' : 'green'"
                        >

                        <v-badge
                          :color="$store.state.cart.findIndex(p => p._id === product._id) === -1 ? 'transparent' : 'primary'"
                          :content="$store.state.cart.find(p => p._id === product._id) ? $store.state.cart.find(p => p._id === product._id).countProduct : ''"
                          :value="$store.state.cart.find(p => p._id === product._id) ? true : false"
                          overlap
                        >
                        {{$store.state.cart.findIndex(p => p._id === product._id) === -1 ? 'Добавить в корзину  ' : 'В корзине  '}}
                        <v-icon dark>
                          {{$store.state.cart.findIndex(p => p._id === product._id) === -1 ? 'mdi-cart-plus' : 'mdi-check'}}
                        </v-icon>
                      </v-badge>
                        </v-btn>
                      </template>
                      <span>{{$store.state.cart.findIndex(p => p._id === product._id) === -1 ? 'Добавить в корзину' : 'ещё +1'}}</span>
                    </v-tooltip>
                  </v-container>
                </v-col>
              </v-row>
          <v-card-text>
            <v-expansion-panels focusable>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h3>Описание</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{product.description}}
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h3>Свойства</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table>
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left">
                            свойство
                          </th>
                          <th class="text-left">
                            значение
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="prop in product.properties"
                          :key="prop.key"
                        >
                          <td>{{ prop.key.trim() }}</td>
                          <td>{{ prop.value.trim() }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          </v-container>
        </v-row>
      </v-col>
      </v-row>
    </v-container>

    <v-card-actions>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CardDetail",
  props: ['item'],

  data () {
    return {
      tabs: null,
      id: this.item,
      carusel: 0,
      indexActivePhoto: 0
    }
  },

  computed: {
    product: function () {
      let obj = {}
      this.$store.state.allProducts.forEach((product) => {
        if (product._id === this.item) {
          for (let p in product) {
            obj[p] = product[p]
          }
        }
      })
      console.log(obj)
      return obj
    },

    activePhoto: function () {
      let photo = this.product.photos ? this.product.photos[this.indexActivePhoto] : ''
      return photo
    }
  }
}
</script>

<style>
  .img-gallery {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }

  .img-wrap {
    width: 50px;
    height: 50px;
  }

</style>
