<template>
  <div class="pricing-component mini-spacer">
            <v-container>
                <v-row justify="center">
                    <v-col 
                    v-for="item in list"
                    :key="item.id"
                    cols="12" sm="8" md="6" lg="3">
                        <v-card outlined elevation="0" class="pricing-card overflow-hidden">
                            <v-card-title class="justify-center">
                                <h4 class="font-weight-regular font-18">{{item.title}}</h4>
                            </v-card-title>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-text class="text-center pa-10">
                                <div>
                                    <sup>₽</sup>
                                    <span class="price-text">{{item.price}}</span>
                                </div>
                                <p class="mt-8 pt-4">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        категория
                                                    </th>
                                                    <th class="text-left">
                                                        количество
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="row in item.table"
                                                    :key="row.key">
                                                    <td class="text-left">{{row.key}}</td>
                                                    <td class="text-left">{{row.value}}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </p>
                                </v-card-text>
                            <v-card-actions>
                                <v-btn block large depressed color="info" class="text-uppercase rounded-t-0">
                                оформить заказ
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
</template>

<script>
export default {
    name: 'Pricing',
    props: {
        list: Array
    }

}
</script>

<style>

</style>