<template>
  <div class="portfolio-component">
            <v-container>
                <v-row class="mt-13">
                    <v-col 
                    v-for="item in list"
                    :key="item.id"
                    cols="12" 
                    md="6" 
                    lg="4">
                        <v-card class="portfolio-card overflow-hidden">
                            <div class="portfolio-img" :style="{maxHeight: mH + 'px'}">
                                <img 
                                :src="item.img" 
                                class="img-fluid" 
                                alt="portfolio" />
                            </div>
                            <v-card-text>
                                <h5 class="font-weight-medium font-18">
                                    {{item.title}}
                                </h5>
                                <p class="font-14 mb-0">{{item.text}}</p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
</template>

<script>
export default {
    name: 'Portfolio',

    props: {
        list: Array,
        mH: String
    }

}
</script>

<style>

</style>