<template>
  <v-expansion-panels
      v-model="isOpen"
      focusable>
    <v-expansion-panel>
      <v-expansion-panel-header>Фильтры</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col>
            <v-autocomplete
                hide-selected
                max-height="150px"
                deletable-chips
                :menu-props="{ closeOnContentClick: true }"
                class="my-4"
                clearable
                :allow-overflow="false"
                v-model="selectedCategories"
                :items="categories"
                outlined
                multiple
                hide-details
                chips
                small-chips
                label="Категория"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <p
                class="subtitle-1"
            >Цена</p>
            <v-row>
              <v-col>
                <v-text-field
                    label="min"
                    outlined
                    dense
                    v-model="minPrice"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    label="max"
                    outlined
                    dense
                    v-model="maxPrice"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
                :items="sortParams"
                v-model="selectedSort"
                label="Сортировка"
                outlined
                dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row
            class="justify-center mb-3"
        >
            <v-btn
                class="mr-4"
                color="primary"
                @click="isOpen = false"
            >
              Свернуть
            </v-btn>
            <v-btn
                color="error"
                @click="resetFilter"
            >
              Сбросить
            </v-btn>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "FilterProduct",

  props: ['sort', 'selCategories', 'minprice', 'maxprice'],

  data () {
    return {
      isOpen: false,
      sortParams: [
        'Цена: по возрастанию',
        'Цена: по убыванию',
        'Имя: по возрастанию',
        'Имя: по убыванию',
      ],
      categories: [
        "Гирлянды для дома",
        "Гирлянды для улицы",
        "Мастерская",
        "Дюралайт",
        "Гибкий Неон",
        "Лента LED",
        "Фигуры",
        "Ёлки",
        "Деревья LED",
        "Новогодний декор"
      ],
    }
  },
  computed: {
    selectedCategories: {
      get () {
        return this.selCategories;
      },
      set (value) {
        if (this.$route.query.categories != value.join(',')) {
          this.$router.push({ query: Object.assign({}, this.$route.query, { categories: value.join(',') }) });
        }
      }
    }, 
    minPrice: {
      get () {
        return this.minprice
      },
      set (value) {
        if (this.$route.query.minprice != value) {
        this.$router.push({ query: Object.assign({}, this.$route.query, { minprice: value }) });
      }
      }
    },
    maxPrice: {
      get () {
        return this.maxprice
      },
      set (value) {
        if (this.$route.query.maxprice != value) {
        this.$router.push({ query: Object.assign({}, this.$route.query, { maxprice: value }) });
      }
      }
    },
    selectedSort: {
      get () {
        return this.sort
      },
      set (value) {
        if (this.$route.query.sort != value) {
        this.$router.push({ query: Object.assign({}, this.$route.query, { sort: value }) });
      }
      }
    }
  },
  methods: {
    resetFilter () {
      this.minPrice = 0
      this.maxPrice = 300000
      this.selectedSort = 'default';
      this.selectedCategories = []
      this.isOpen = false
    }
  }
}
</script>

<style scoped>

</style>
