<template>
    <div>
        <HeaderBanner
            :title="sections.header.title"
            :text="sections.header.text"
            :color="sections.header.color"
            :img="sections.header.img"
            :gradient="sections.header.gradient"/>

        <TitleWithText
            :title="sections.features.title"
            :text="sections.features.text"
            :chip="sections.features.chip"/>

        <FeaturesSection :list="sections.features.list"/>

        <TitleWithText
            :title="sections.portfolio.title"
            :text="sections.portfolio.text"
            :chip="sections.portfolio.chip"/>

        <Portfolio 
        :mH="sections.portfolio.mH"
        :list="sections.portfolio.list"/>


        <TitleWithText
            :title="sections.contacts.title"
            :text="sections.contacts.text"
            :chip="sections.contacts.chip"/>

        <ContactForm/>
    </div>
</template>
<script>
import HeaderBanner from "../components/pageComponents/HeaderBanner.vue";
import TitleWithText from "../components/pageComponents/TtitleWithText.vue";
import FeaturesSection from "../components/pageComponents/FeaturesSection.vue";
import Portfolio from "../components/pageComponents/Portfolio.vue";
import ContactForm from "../components/pageComponents/ContactForm.vue"

export default {
    name: 'Manufacture',
    components: {
        HeaderBanner,
        TitleWithText,
        FeaturesSection,
        Portfolio,
        ContactForm
    },

    data () {
        return {
            sections: {
                header: {
                    title: 'Изготовление объёмных LED фигур и консолей',
                    text: 'Для тех, кому недостаточно типовых решений, мы предлагаем изготовить эксклюзивные изделия в полном соответствии с вашим техническим заданием',
                    color: '#000',
                    gradient: 'linear-gradient(180deg, rgba(0,0,0,1) 79%, rgba(93,167,238,1) 100%)',
                    img: require('@/assets/olen.webp')
                },
                features: {
                    chip: 'почему мы',
                    title: 'Преимущества работы с нами',
                    text: 'Производство в целом вещь не простая. А производство эксклюзивных изделий не просто вдвойне. Но мы справляемся',
                    list: [
                        {
                            id: 1,
                            title: 'Опыт',
                            text: 'В люблм деле количество реализованных проектов - очень важная метрика для определения компетенций исполнителя. Мы не первый год в деле, и количество реализованнх нами проектов позволяет предугадывать желания заказчика, предлагая лучшее решение',
                            icon: 'mdi mdi-star'
                        },
                        {
                            id: 2,
                            title: 'Квалификация',
                            text: 'Спецефичность и сезонность нашей деятельности накладывает определённые трудности при поиске сотрудников для нашего производства. Но мы нашли решение - за несколько лет вырастили своих специалистов. И мы уверены в их профессионализме',
                            icon: 'mdi mdi-account-multiple-check'
                        },
                        {
                            id: 3,
                            title: 'Ценообразование',
                            text: 'Если коротко - мы не борзеем. При неизменном качестве выпускаемой продукции, постоянно находимся в поиске оптимальных схем организации производственного процесса, что позволяет нам предложить цены ниже рынка',
                            icon: 'mdi mdi-currency-usd'
                        }
                    ]
                },
                portfolio: {
                    chip: 'Примеры работ',
                    title: 'Наизготавливали кому-то. Изготовим и Вам',
                    text: '',
                    mH: '420',
                    list: [
                        {
                            id: 1,
                            title: 'Новогодний шар',
                            text: 'Огромных размеров новогодний шар выглядит весьма эффектно',
                            img: require('@/assets/images/portfolio/ball.jpg')
                        },
                        {
                            id: 2,
                            title: 'Сани',
                            text: 'Лучший новогодний транспорт в лучшем новогоднем оформлении. Дед будет доволен',
                            img: require('@/assets/images/portfolio/transport.jpg')
                        },
                        {
                            id: 3,
                            title: 'Олень, который светится',
                            text: 'Олени Санта-Клауса - беспроигрышный вариант при новогоднем оформлении. Дети визжат от восторга, взрослые одобрительно кивают',
                            img: require('@/assets/images/portfolio/olen.jpg')
                        },
                        {
                            id: 4,
                            title: 'Комплексный заказ',
                            text: 'Кстати, при заказе нескольких изделий, действует система скидок. Подробности по телефону',
                            img: require('@/assets/images/portfolio/others.jpg')
                        },
                        {
                            id: 5,
                            title: 'Снежинка',
                            text: 'Пожалуй самое популярное изделие - новогодняя снежинка',
                            img: require('@/assets/images/portfolio/bigSnow.jpg')
                        },
                        {
                            id: 6,
                            title: 'Парад снежинок',
                            text: 'Каких только снежинок мы не изготавливали - большие и маленькие, белые и зелёные, круглые и квадратные',
                            img: require('@/assets/images/portfolio/manySnow.jpg')
                        },
                    ]
                },
                contacts: {
                    chip: 'пишите',
                    title: 'Связаться с нами',
                    text: '',
                }
            }
        }
    }

}
</script>

<style>
.portfolio-img {
    max-height: 420px;
}

</style>
