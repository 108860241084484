<template>
  <div class="banner-wrapper" :style="{minHeight: 'calc(100vh - 120px)', background: [color, gradient],}">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="7" lg="6" class="d-flex align-center">
                        <div class="text-center text-md-left">
                            <h1 class="banner-title font-weight-bold white--text">
                                {{title}}
                            </h1>
                            <h4 class="banner-subtitle white--text font-weight-regular">
                                {{text}}
                            </h4>
                            <div class="mt-16 pt-2">
                                <v-row class="justify-start">
                                    <v-col cols="12" md="6" lg="4" class="d-flex justify-center">
                                        <v-btn color="error" class="mr-0 mr-md-8 mb-5 mb-md-0 btn-custom-md" large target="_blank">
                                            Позвонить 
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="4" class="d-flex justify-center">
                                        <v-btn large  class="btn-custom-md" outlined color="white">
                                            Написать
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="5" lg="6">
                        <v-img style="border-radius: 5%" :src="img" alt="banner" />
                    </v-col>
                </v-row>
            </v-container>
        </div>
</template>

<script>
export default {
    name: 'HeaderBanner',
    props: {
        title: String,
        text: String,
        color: String,
        img: String,
        gradient: String,
    }

}
</script>

<style>

</style>